$field_color_focus: rgb(255,255,255,1);
$field_color_blur: rgba(255,255,255,.58);
$field_color_blur2: rgba(255,255,255,.26);

$search_toolbar_height_def: 64px;
$flight_toolbar_height_def: 48px;

$toolbar_height_def: $search_toolbar_height_def + $flight_toolbar_height_def;
$toolbar_height_720_autoreplay: $search_toolbar_height_def + (2 * $flight_toolbar_height_def);
$toolbar_height_570: $search_toolbar_height_def;
$toolbar_height_480: $search_toolbar_height_def * 2;

$flight_counter_top_buffer: 10px;

$primary_color: #184a7d;
$secondary_color: #009ed2;
