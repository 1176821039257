@import '/src/app/variables';

@media (max-width: 720px) {
  .autoreplay .mini-sidenav {
    top: $toolbar_height_720_autoreplay !important;
  }

  .autoreplay app-map {
    top: $toolbar_height_720_autoreplay !important;
  }

  .autoreplay app-flight-toolbar .mat-toolbar {
    height: 96px !important;
    flex-wrap: wrap !important;
  }

  app-flight-toolbar form {
    justify-content: space-between !important;
    width: 100% !important;
  }

  app-search-toolbar .weather {
    display: none !important;
  }

  .autoreplay app-flight-counter {
    top: $toolbar_height_720_autoreplay + $flight_counter_top_buffer !important;
  }
}

@media (max-width: 570px) {
  .mini-sidenav, .autoreplay .mini-sidenav {
    top: $toolbar_height_570 !important;
  }

  app-map, .autoreplay app-map {
    top: $toolbar_height_570 !important;
  }

  app-flight-counter, .autoreplay app-flight-counter {
    top: $toolbar_height_570 + $flight_counter_top_buffer !important;
  }
}

@media (max-width: 480px) {
  app-flight-toolbar {
    display: none !important;
  }

  app-search-toolbar mat-toolbar.mat-toolbar {
    height: $toolbar_height_480 !important;
    flex-wrap: wrap !important;
  }
  .mini-sidenav, .autoreplay .mini-sidenav {
    top: $toolbar_height_480 !important;
  }
  app-map, .autoreplay app-map {
    top: $toolbar_height_480 !important;
  }

  app-flight-counter, .autoreplay app-flight-counter {
    top: $toolbar_height_480 + $flight_counter_top_buffer !important;
  }

  .clock {
    width: 100% !important;

    DIV {
      display: inline-block !important;
      padding-right: 12px !important;
    }
  }

  .macnoms-brand {
    position: absolute !important;
    left: 0px !important;
    top: 64px !important;
  }
}
