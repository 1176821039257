/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import '/src/media-styles.scss';
@import '/src/app/variables';

BODY {
  margin: 0px;
}


/** START - Override the CSS for some open layer controls **/
.ol-scale-line {
  left: 80px !important;
  background: rgba(0,60,136,0.3) !important;

  .ol-scale-line-inner {
    border: 1px solid #eee;
    border-top: none !important;
    color: #eee;
  }
}

.ol-control {
  background-color: rgba(255,255,255,0.4) !important;
  padding: 2px;

  &:hover {
    background-color: rgba(255,255,255,0.6) !important;
  }

  button {
    color: white !important;
    font-size: 1.14em !important;
    background-color: rgba(0,60,136,0.5) !important;

    &:hover {
      background-color: rgba(0,60,136,0.7) !important;
      color: white !important;
      outline: none !important;
    }
  }
}

// The new zoom control styling is garbage so we are going to revert it back to the original styling


.ol-attribution {
  right: 8px !important;
  bottom: 8px !important;
  border-radius: 2px !important;
  background: rgba(255,255,255,0.6) !important;
  padding: 4px !important;

  a {
    text-decoration: none !important;
    color: blue !important;
  }
}

/** END - Override the CSS for some open layer controls **/


/** START - MatSidenav Overrides **/
mat-sidenav {
  // These are needed for proper scrolling of content in the sidenav
  &.full-sidenav .hidden {
    visibility: hidden;
    transform: translate(-100%);
    position: absolute;
  }

  .mat-drawer-inner-container {
    overflow: hidden;
  }

  .mat-mdc-tab-group {
    overflow: hidden;
  }
}
/**END - MatSidenav Overrides **/


/** START - MatDialog Overrides **/
mat-dialog-container {
  // An override to make sure the buttons appear in the middle for dialogs
  .mat-mdc-dialog-actions {
    justify-content: center !important;
  }
}

/** END - MatDialog Overrides **/


/** START - Generic Field overrides **/
mat-form-field {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }

  // Remove the padding on the right and left of the field text
  .mdc-text-field, .mat-mdc-form-field-error-wrapper {
    padding: 0px !important;
  }
}
/** END - Generic Field overrides **/


/** START - Specific field overrides **/
app-search-toolbar, app-flight-toolbar {
  .mat-mdc-input-element {
  	caret-color: $field_color_blur !important;
  	color: $field_color_focus !important;
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: $field_color_focus !important;
  }

  .mdc-line-ripple {
  	&:before, &:after {
      border-bottom-color: $field_color_focus !important;
    }
  }

  #search-field {
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      padding-top: 28px;
      padding-bottom: 4px;
    }

    .mat-mdc-form-field-icon-prefix {
      padding: 24px 0 0 0 !important;
      align-self: end;

      .mat-icon{
        padding: 0 8px 0 0;
      }
    }

    .mat-mdc-form-field-icon-suffix {
      align-self: auto;

      .mat-mdc-button-touch-target {
        height: 1em;
        width: 1em;
      }

      .mat-mdc-icon-button {
        height: 1em !important;
        width: 1em !important;
        padding: 0;
        line-height: 24px;
        vertical-align: middle;

        .mat-icon {
          padding: 0;
          font-size: 16px !important;
          color: $field-color-focus;
        }
      }
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 40px !important;
    }
  }

  .mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-160%) scale(0.75) !important;
  }
}

app-flight-toolbar {
  .mat-mdc-form-field {
    padding: 0 12px;
  }

  .mat-mdc-slider {
    flex: 1 1 auto;
  }

  .mat-mdc-select-value {
    color: $field_color_focus;
  }

  .mat-mdc-select-arrow {
    color: $field_color_blur;
  }

  .mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
    color: $field_color_focus;
  }

  .mat-mdc-form-field-icon-suffix {
    align-self: baseline;

    .mat-mdc-icon-button {
      height: 1.5em !important;
      width: 1.5em !important;
      font-size: 14px;
      padding: 4px !important;

      svg {
        height: 14px;
        width: 14px;
        color: $field_color_focus;
      }
    }
  }


  .width-220 .mat-mdc-form-field-infix {
    width: 220px;
  }

  .width-120 .mat-mdc-form-field-infix {
    width: 120px;
  }

  .width-100 .mat-mdc-form-field-infix {
    width: 100px;
  }

  .width-80 .mat-mdc-form-field-infix {
    width: 80px;
  }

  .mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
    transform: translateY(0px);
  }

  /* These styles condense the vertical centering of the input fields */
  .mat-mdc-form-field, .mat-mdc-floating-label, .mat-mdc-select {
    font-size: 14px !important;
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 18px !important;
    padding-bottom: 0px !important;
    min-height: 40px;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 30px !important;
  }


  /* END */

  /* Slider */
  .mat-mdc-slider {
    --mdc-slider-active-track-height: 2px;
    --mdc-slider-inactive-track-height: 2px;
    --mdc-slider-handle-width: 14px;
    --mdc-slider-handle-height: 14px;
    --mdc-slider-handle-color: $field_color_focus !important;
    --mdc-slider-focus-handle-color: $field_color_focus !important;
    --mdc-slider-hover-handle-color: $field_color_focus !important;
    --mdc-slider-active-track-color: $field_color_focus !important;
    // Not sure why, but the next style cannot use my $field_color_focus variables
    // It just won't work if we try to do that, so we hardcode the color instead
    --mdc-slider-inactive-track-color: rgb(255,255,255,1) !important;
  }
  /* END SLIDER */
}


/** END - Specific field overrides **/

/* START - List of options for the Search field autocomplete */
.mat-mdc-autocomplete-panel {
  mat-option.mat-mdc-option {
    line-height: 18px;
    min-height: 36px;
    font-size: 14px;
  }
}
/** END - Autocomplete overrides **/

/* START - Drop down menus for select fields in the top bar
  NOTE: We are using an override class option in the mat-select called 'panelClass'
**/
.retro-panel-class {
  padding: 0 !important;

  .mat-mdc-option.mdc-list-item {
    font-size: 12px;
    line-height: 3em;
    min-height: 3em;
    padding: 0 1em;

    & .mdc-list-item__primary-text {
      line-height: 1.5em;
    }
  }
}

/** END - Drop down overrides **/


/** START - MatButton Overrides **/
app-root {
  .mat-mdc-icon-button {
    &.mat-mdc-button-base {
      width: 40px;
      height: 40px;
      padding: 6px;
    }

    .mat-mdc-button-touch-target {
      height: 40px;
      width: 40px;
    }
  }
}

.sidenav-body {
  .mat-mdc-icon-button {
    .mat-mdc-button-persistent-ripple {
      border-radius: unset;
    }
  }

  .mdc-list-item {
    .mat-badge-medium .mat-badge-content {
      right: -14px;
      top: -8px;
    }
  }
}


/** START - Override the description and distance text that appears for the measure tool **/
app-map {
  .measure_text {
    color: #ffcc33;
  }
}
/** END - Override the description and distance text that appears for the measure tool **/


app-main-sidenav, app-user-sidenav {
  .mat-mdc-nav-list {
    padding: 8px 0;

    .mat-mdc-list-item {
      height: 40px !important;

      .mat-mdc-icon-button {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        padding: 0 16px;
        width: 100%;
        text-align: left;
        opacity: .87;

        /* Prevents the focused overlay from looking stretched out or incorrectly offset from the icon */
        .mat-mdc-focus-indicator {
          width: 40px;
          margin-left: 12px;
        }

        .label {
          padding-left: 16px;
          font-size: 14px;
          font-weight: 500;
          vertical-align: middle;
          font-family: 'Roboto', sans-serif;
        }

        .mat-icon {
          vertical-align: text-bottom;
        }
      }
    }
  }
}
/** END - MatButton Overrides **/


/** START - MatSnackbar Overrides **/
.mat-mdc-snack-bar-container.flight-tracker-snackbar {
  .mdc-snackbar__surface {
    background: $primary_color !important;
  }

  .mat-mdc-snack-bar-action:not(:disabled) {
    color: $secondary_color !important;
  }
}
/** END - MatSnackbar Overrides **/


/** START - MatBottomSheet Overrides **/
mat-bottom-sheet-container {
  &.mat-bottom-sheet-container {
    min-width: 100%;
    width: 100%;
  }

  .mat-icon {
    font-size: 18px;
    vertical-align: text-bottom;
  }

  .mdc-list-shrink {
    .button-bar {
      .mat-mdc-icon-button {
        mat-icon {
          vertical-align: baseline;
        }
      }
    }
  }

  .button-bar {
    .mat-mdc-icon-button {
      width: 36px;
      height: 36px;
      padding: 6px;

      mat-icon {
        height: 18px;
        width: 18px;
        font-size: 12px;
        vertical-align: middle;
      }
    }

    .mat-mdc-button-touch-target {
      height: 36px;
      width: 36px;
    }
  }
}
/** END - MatBottomSheet Overrides **/


/** START - Custom classes that exist on all of our dialogs **/
app-authentication-dialog, app-create-account-dialog, app-address-help-dialog, app-weather-info-dialog, app-gate-info-dialog {
  display: flex;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  flex-direction: column;
  overflow: hidden;

  .content {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    position: relative;

    .dialog-title {
      font-size: 24px;
      font-weight: 300;
      padding: 18px;
      text-align: center;
      color: white;
      background-color: #009ed2;
    }

    .dialog-body {
      position: relative;
      overflow-x: hidden;
      overflow-y: auto;
      padding-bottom: 12px;
      color: #000000;

      &.padded {
        padding: 12px 24px;
      }
    }
  }
}
/** END - Custom classes that exist on all of our dialogs **/

/** START - Overrides for our dialogs that have input fields **/
app-authentication-dialog, app-create-account-dialog, app-address-help-dialog {
  mat-form-field.max-width {
    width: 100%;
  }

  .mat-dialog-actions {
    justify-content: center;
    margin-bottom: -24px;
  }

  .link-bar {
    padding: 12px 0 0 0;
    font-size: 14px;
    color: $primary_color;
    text-align: right;
    display: flex;
    justify-content: space-between;

    span {
      display: inline-block;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .mat-form-field {
    padding: 6px 0px;
  }

  .form-error-bar {
    font-size: 14px;
  }
}

/** END - Overrides for our dialogs with input fields **/



/** START - Overrides for all of our sidenavs **/
.mac-sidenav {
  // IMPORTANT - sets the foundation for proper scrolling
  display: flex;
  flex-direction: column;
  height: 100%;

  h2 {
    letter-spacing: 0;
  }

  &.minified {
    .mat-mdc-nav-list {
      .mat-mdc-list-item {
        .mat-mdc-icon-button {
          text-align: center;
        }
      }
    }
  }

  DIV.sidenav-body, DIV.sidenav-body form {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;

    > H2 {
      padding: 8px 24px 0 24px;
      margin: 0px;
    }

    > P {
      padding: 0 24px;
    }

    > A {
      padding: 0 24px;
    }

    /* Radio buttons and check boxes in the sidenav */
    mat-list-option, mat-list-item {
      &.mat-mdc-list-item {
        font-size: 14px;
        font-weight: 500;
        height: 40px;
      }

      &.mat-mdc-list-item-disabled {
        opacity: .26;
      }
    }

    .mdc-form-field {
      width: 100%;

      label {
        width: 100%;
        padding-left: 8px;
        font-weight: 500;
      }
    }

    .mat-mdc-radio-button {
      .mdc-radio {
        padding: calc((var(--mdc-radio-state-layer-size, 40px) - 16px) / 2);
        width: 16px;
        height: 16px;

        .mdc-radio__background {
          width: 16px;
          height: 16px;

          &::before {
            top: calc(-1 * (var(--mdc-radio-state-layer-size, 40px) - 16px) / 2);
            left: calc(-1 * (var(--mdc-radio-state-layer-size, 40px) - 16px) / 2);
          }

          .mdc-radio__inner-circle {
            border-width: 8px;
          }
        }
      }
    }

    /* Check boxes in the side nav */
    mat-list-option {
      &:not(.selectall) {
        .mdc-list-item__content {
          padding-left: 8px;

          .mdc-list-item__primary-text {
            font-weight: 500;
            font-size: 14px;
          }
        }
      }

      &.mdc-list-item--with-trailing-checkbox {
        .mdc-list-item__end {
          margin-right: 16px;
          --mdc-checkbox-selected-checkmark-color: #ffffff;
        }
      }

      &.mat-mdc-list-option {
        .mdc-checkbox {
          width: 16px;
          height: 16px;
          padding: calc((var(--mdc-checkbox-state-layer-size, 40px) - 16px) / 2);

          .mdc-checkbox__background {
            width: 16px;
            height: 16px;
            top: calc((var(--mdc-checkbox-state-layer-size, 40px) - 16px) / 2);
            left: calc((var(--mdc-checkbox-state-layer-size, 40px) - 16px) / 2);
          }
        }
      }
    }
  }

  mat-tab-header.mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .mat-mdc-tab {
    padding: 0 12px;
    min-width: 100px;
  }
}

/** END - Overrides for all of our sidenavs **/

/** Overides for the Help Sidenav **/
app-help-sidenav {
  .mat-mdc-tab-body-content {
    padding: 0px 24px;
  }
}

/** END - Overrides for Help **/


/** Overrides for the App Filter Sidenav **/
app-filter-sidenav {

  mat-list-item {
    button.mat-mdc-icon-button {
      width: 100% !important;

      & .mat-mdc-button-persistent-ripple::before {
        background-color: transparent;
      }

      & .label {
        padding-left: 12px;
        font-size:16px;
        font-family: Roboto, "Helvetica Neue", sans-serif;
        font-weight: 500;
        vertical-align: middle;
      }

      & mat-icon {
        vertical-align: text-bottom;
      }
    }
  }

  mat-list-option {
    &:not(.selectall) {
      padding-left: 30px;
    }

    &.mat-mdc-list-item.mat-mdc-list-option {
      height: 40px !important;
      align-items: center;

      & .mdc-list-item__primary-text {
        font-size: 14px;
        font-weight: 500;
      }

      & .mdc-checkbox {
        width: 16px;
        height: 16px;
      }
    }

    &.selectall {
      &.mat-mdc-list-item.mat-mdc-list-option {
        background-color: #fafafa;
        line-height: 14px;
        height: 48px !important;
      }

      & .mdc-list-item__content {
        white-space: initial;
        text-overflow: initial;
        align-self: center;
      }

      & .mdc-list-item__primary-text {
        font-weight: 100 !important;
        line-height: 14px;
        white-space: normal;
        text-overflow: initial;
      }
    }
  }
}


/** End overrides for App Filter Sidenav **/


/** Overrides for App Info sidenav **/
app-info-sidenav {
  mat-slide-toggle {
    padding: 12px 16px;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;

    label {
      font-weight: 500;
      font-size: 16px;
    }
  }
}
/** End overrides for App Info sidenav **/


/** Overrides for the Complaint Sidenav **/
app-complaint-sidenav {
  .subtitle {
    line-height: 1.5em;
    font-weight: bold;
    padding: 16px 24px 0px;
  }

  .mat-mdc-raised-button.mat-primary {
    --mdc-protected-button-label-text-color: #fff;
  }

  .mat-mdc-form-field, .mat-mdc-floating-label, .mat-mdc-select {
    font-size: 14px;
  }

  .mat-mdc-form-field {
    position: relative;
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-bottom: 0;
  }

  .mat-mdc-form-field-infix {
    min-height: 48px;
  }
}
/** End override for Complaint sidenav **/

/* Override style for the select fields on the complaint form so they stand out a bit better and don't blend
	 with the rest of the form */
.complaint-select-panel {
  border: 1px solid rgba(0, 0, 0, 0.4);
  box-sizing: border-box;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), -1em -3em 0px -.5em rgba(255, 255, 255, 0.9) !important;
}


/* Overrides to the timepicker component so that it appears in the sidenav on iPads and iPhones (position: static does not work on those devices) */
//mat-form-field ngx-material-timepicker {
//  position: absolute;
//  top: 3.5em;
//  left: 1.5em;
//  display: block;
//  width: 100%;
//  height: 432px;
//
//  .timepicker-backdrop-overlay {
//    opacity: 0;
//  }
//
//  .clock-face {
//    width: 245px !important;
//    height: 245px !important;
//    padding: 7.5px !important;
//  }
//
//  .clock-face__number--outer {
//    height: calc(245px / 2 - 7.5px) !important;
//  }
//}
//
///* Without this, the timepicker would never appear on a iPhone or iPad */
//.timepicker-overlay {
//  position: absolute !important;
//}

/* Styles to make the create account dialog have a fixed head and button bar and scroll in the middle */
.create-account-overlay {
  position: absolute !important;
  overflow: hidden;
}

app-create-account-dialog {
  position: absolute;
}

app-create-account-dialog {
  .mat-step-label {
    padding-top: 8px !important;
  }

  .pseudolink {
    color: #184a7d;
    cursor: pointer;
    font-size: 14px;
  }

  .pseudolink:hover {
    text-decoration: underline;
  }

  .content, .mat-dialog-actions {
    margin: 0px;
  }

  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 24px;
    padding-bottom: 0px;
  }

  .mat-mdc-form-field-infix {
    min-height: 52px;
  }

  form {
    position: relative;
    overflow: inherit;
    height: 100%;
    display: flex;
    flex-direction: column;

    div.content {
      display: flex;
      flex-direction: column;
      overflow: inherit;
      flex-grow: 1;

      div.dialog-body {
        overflow: inherit;
        flex-grow: 1;
        height: 100%;
        padding-bottom: 0px;

        mat-horizontal-stepper {
          display: flex;
          flex-direction: column;
          overflow: inherit;
          position: relative;
          height: 100%;

          .mat-horizontal-content-container {
            overflow-y: auto;
            padding-bottom: 0px;
            flex-grow: 1;
          }
        }
      }
    }
  }

  #search-field {
     .mat-mdc-form-field-icon-prefix {
      padding: 24px 0 0 0 !important;
      align-self: end;

      .mat-icon{
        padding: 10px 8px 0 0;
        height: 16px;
      }
    }

    .mat-mdc-form-field-icon-suffix {
      align-self: auto;

      .mat-mdc-button-touch-target {
        height: 1em;
        width: 1em;
      }

      .mat-mdc-icon-button {
        height: 1em !important;
        width: 1em !important;
        padding: 0;
        line-height: 24px;
        vertical-align: middle;

        .mat-icon {
          padding: 0;
          font-size: 16px !important;
        }
      }
    }

    .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
      top: 36px !important;
    }

    .mdc-text-field--filled .mdc-floating-label--float-above {
      transform: translateY(-160%) scale(0.75) !important;
    }
  }
}
